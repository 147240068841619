import React from 'react'
import './author.css'

const baseURL = 'https://carla-blog-images-webp.s3.eu-west-1.amazonaws.com/'

function BlogAuthor({ content, isSanityContent = false }) {
  const authorImage = content?.photo?.indexOf(baseURL) !== -1 ? content.photo : `${baseURL}${content?.photo}`
  const lkLink = `<a target="_blank" style="display: inline-block; color: #FE8128;" href="${content.linkedin}">LinkedIn</a>`
  const igLink = `<a target="_blank" style="display: inline-block; color:#FE8128;" href="${content.instagram}">Instagram</a>`
  const twLink = `<a target="_blank" style="display: inline-block; color:#FE8128;" href="${content.twitter}">Instagram</a>`

  let modifiedContent = content.description
  if (isSanityContent) {
    modifiedContent = modifiedContent.replace(/instagram/i, igLink)
    modifiedContent = modifiedContent.replace(/twitter/i, twLink)
    modifiedContent = modifiedContent.replace(/linkedin/i, lkLink)
  }

  return (
    <section className='author-section'>
      {authorImage && <img height={65} width={65} src={authorImage} alt='rentcarla_author_avatar' />}
      <div className='author-text'>
        <div className='author-title'>
          <p className='author-header'>{content.name}</p>
          <p className='author-span'>{content.dueDate}</p>
        </div>
        <p className='author-subHeader' dangerouslySetInnerHTML={{ __html: modifiedContent }} />
      </div>
    </section>
  )
}

export default BlogAuthor
